.ol-attribution {
    display: none;
}

.ol-scale-line-inner {
    font-size: 15px!important;
    font-weight: bolder;
    border-bottom-width: 2px!important;
    border-right-width: 2px!important;
    border-left-width: 2px!important;
    color: black!important;
    z-index:1000!important;
}

.ol-scale-line {
    /* background: rgba(255, 255, 255, 0.8); */
    padding: 5px;
    border-radius: 4px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 12px; /* Adjust for mobile readability */
    z-index: 1000!important;
}
.ol-scale-bar-inner,
.ol-scale-bar,
.ol-overlaycontainer-stopevent {
    z-index: 1000!important;
    opacity: 1;
    background-color: transparent;
}
.ol-layers {
    z-index:-1!important;
    opacity: 1;
}

