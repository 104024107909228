.ionSpinner {
    width: 35px;
    height: 35px;
    position: relative;
    top: 0;
    left: 0;
    --color: white;
}

.spinnerContainer {
    border-radius: 100px;
    width: 35px;
    height: 35px;
}
