.topSpacer {
    /* height: var(--toender-filter-level1-line-height); */
    padding: 20px;
    font-weight: 600;
}

/* Set the font size of the toggle icon. */
ion-accordion ion-item[slot="header"] ion-icon {
    font-size: var(--toender-filter-level1-icon-size);
}

/* ion-accordion ion-item[slot="header"] ion-icon[slot="end"] .icon-inner svg g

ion-accordion ion-item[slot="header"] ion-icon[slot="end"] .icon-inner {
    fill: red;
    color: blue;
    background-color: red;
}
 */

ion-accordion ion-item[slot="header"] ion-icon[slot="end"] .icon-inner {

    width: 100px;
}

ion-accordion::shadow ion-item[slot="header"] ion-icon[slot="end"] .icon-inner {
    fill: green;
}
