.header, .toolbar {
    color:white;
    --background: #002d4fff;
}


.IonTabButton, .customTabBar {
    --background: #002d4fff;
    background-color: var(--toender-color);
}

/*
.IonTabButton:hover {
    color:white;
}

.IonTabButton:host:hover, .IonTabButton:host IonIcon {
    color: white;
    fill: white;
}


ion-button {
    --color: white;
}

.initialListModalHeight {
    height: 1000px;
}

ion-modal {
    --animation-duration: 10s!important;
}
*/

.IonTabButton:hover > * {
    color: #f0f0f0;
    opacity: 1;
}

.IonTabButton > * {
    color: #f0f0f0;
    opacity: 0.6;
}

.show-modal {
    pointer-events: all;
}


.LogoFlex {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
}

.Nordiq {
    height: 40px;
    margin-bottom: 30px;
}
