.container {
    position: relative;
    padding-top: -10px;
    height: 20px;
    margin-bottom: 3px;
    line-height: 30px;
    padding-left: var(--toender-filter-level2-label-padding-left);
    cursor: pointer;
}

.top {
    position: absolute;
    top: var(--toender-filter-level2-icon-padding-top);
    right: var(--toender-filter-level2-icon-margin-right);
}

.line {
    position: absolute;
    top: 30px;
    right: 62px;
    background-color: transparent;
    height: 35px;
    display: inline-block;
    border-top: 3px solid black;
    width: 50%;
    margin: auto;
}

.bottom {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--toender-filter-light-color);
    height: 60px;
    margin-right: 0;
    width: calc(100% + 20px);
    padding: 10px;
}
