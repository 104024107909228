.categoryHeadline {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 20px
}

.moreButton {
    height: 10px;
    padding-bottom: 30px;
    font-size: 12px;
    margin-top:0;
}

.icon {
    padding-bottom: 30px;
    font-size: 0;
    line-height: 10px;
}

ion-item {
    --border-color: #fff;
    --border-width: 0;
}

.item-inner {
    margin-top:0;
}

.ionContent {
    padding: 15px;
}

.chip {
    float: right;
    background-color: var(--toender-superlightgray);
    margin-right: 25px;
    margin-bottom: 30px;
}

.cls {
    clear: both;
}

.mm {
    margin-bottom: 70px;
}
