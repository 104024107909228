/*

.label {
    padding-left: 10px;
}

.fadeOut {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgb(140, 30, 30));
}

*/

.ionContent {
    padding: 15px;
}
