.header, .toolbar {
    color:white;
    --background: #002d4fff;
}

.right {
    float: right;
}

.mapOverLayFixer {
    margin:8px;
    padding: 15px;
}
