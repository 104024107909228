:root {
    --toender-color: #002d4fff;
    --ion-color-toender-primary-grey: #d7d7d7;

    --toender-header-height: 60px;
    --toender-lightgray: #eff0f0ff;
    --toender-darkgray: #d8d9d9;
    --toender-superlightgray: #f8f8f8;

    /* Filter Modal */

    --toender-filter-light-color: #d7d7d7;
    --toender-filter-dark-color: #c4c3c3;

    /* Level 1 */
    --toender-filter-level1-icon-size: 41px;
    --toender-filter-level1-line-height: 80px;

    /* Level 2 */

    /* Pictogram placement horizontal from right to left */
    --toender-filter-level2-icon-margin-right: 17px;
    --toender-filter-level2-label-padding-left: 5px;

    --toender-filter-level2-icon-padding-top: -1px;

    /* List Modal */

    /* Item */

    --toender-facility-item-background-color: #d9d9d9;

    /* Slide */
    --slide-margin-right: 25px;
    --slide-width: calc(100% - var(--slide-margin-right));

    --ion-color-primary: var(--toender-color);
    --ion-color-primary-shade: var(--toender-color);
    --ion-color-primary-tint: var(--toender-color);
    --ion-color-success: var(--toender-color);

    /* TheMap */

    --frilufts-map-tilelayer-opacity: 0.9;

}

@media (prefers-color-scheme: light) {
    :root {
        --toender-facility-item-background-color: white; /* var(--toender-color); */
        --ion-background-color: pink;
    }
}

body {
    --ion-color-primary: var(--toender-color);
    --ion-background-color: white;
}

@media (prefers-color-scheme: dark) {
    --toender-facility-item-background-color: white; /* var(--toender-color); */
    --ion-color-primary: white; /* var(--toender-color); */
    --ion-background-color: white!important;

}
