:host(.ion-color:not(.item-fill-solid):not(.item-fill-outline)) .item-native,
:host(.ion-color:not(.item-fill-solid):not(.item-fill-outline)) .item-inner {
    border-color: lightgrey; /*var(--ion-color-shade); */
    height: 80px;
}

IonAccordion {
    font-size: 90px;
}


