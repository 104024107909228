.Row {
    width:100%;
    margin-top: 3px;
}

.disabled {
    opacity: 0.5; /* Example: reduce the opacity */
    pointer-events: none; /* Example: disable pointer events */
}

.header, .toolbar {
    color:white;
    --background: #002d4fff;
}

.IonTabButton {
    --background: #002d4fff;
    color: white;
}

.IonTabButton:host:hover .IonTabButton:host IonIcon {
    color: white;
    fill: white;
}

.customTabBar {
    background-color: var(--toender-color);
}

ion-button {
    --color: white; /* Replace with your desired color */
}

ion-modal {
    --animation-duration: 10s!important;
    /* adjust this value to make the animation slower or faster */
}

.iphoneWrapper {
    /* Set the background image */
    background-image: url('../assets/Frame2.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.flexWrapper {
    /*display: flex;*/
    align-items: center;
    padding: 10px;
}
