.mapContainerOne, .background {
    background-color: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 300px;
    opacity: 0.9;
}

.mapContainer {
    height:300px;
}

.detailMapBackground::-webkit-scrollbar {
    display: none !important;
}

.mapContainer::-webkit-scrollbar {
    display: none;
}

.mapContainerOne::-webkit-scrollbar {
    display: none;
}

.background::-webkit-scrollbar {
    display: none;
}

.moreButton {
    height: 10px;
    padding-bottom: 30px;
    font-size: 12px;
    margin-top:0;
}

.mapContainerOne {
    position: relative;
    display: inline-block;
}

.mapContainerOne::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #ddd, #e8e8e8, #ddd);
    opacity: 0.3;
    animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.7;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0.7;
    }
}
