.active {
    opacity: 1;
    height: 100%;
}

.inactive {
    opacity: 0.5;
    height: 100%;
}

.swiper {
    height: auto;
}
