.label {
    font-size: 10px;
}

.ionGrid {
    --ion-grid-padding: 20px;
}

.mapOverLayFixer {
    padding: 30px;
}

.mapContainer, iframe {
    width: var(--slide-width);
    height: 393px;
    margin-top:20px;
}

ion-row {
    margin-bottom: 5px;
}

/*
TODO: unhardcode
 */
.isObject {
    padding-left: 14px;
    /* padding-right: 21px; */
    width: var(--slide-width);
}

.isNotObject {
    width: var(--slide-width);
    padding-left: 0;
    margin-left: 0;
    padding-right: 0;
}

.paddingLeftNull {
    padding-left:0!important;
}
