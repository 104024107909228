.label {
    font-size: 10px;
}

.widget {
    position: relative;
    display: inline-block;
}

.content, .dangerousContent {
    font-size: 14px;
}

.widget::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(45deg, #ddd, #e8e8e8, #ddd);
    opacity: 0.3;
    animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.7;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0.7;
    }
}
