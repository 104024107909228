.header {
    color:white;
    --background: #002d4fff;
    height: 80px;
}

.toolbar {
    --background: #002d4fff;
    color:white;
    height: 80px;
}

.buttons {
    padding: 12px 14px 14px 11px;
}

.brand {
    width: 45px;
    height: 52px;
    float: left;
}

.line {
    margin-left: 14px;
    margin-right: 14px;
}

.title {
    font-size: 16px;

}

.langSelector {
    width:30px;
    height: 30px;
    float: right;
}

.right {
    float: right;
}

.lang {
    margin: 0;
    width: 100%;
}

.here {
    margin:0;
    width:80%;
    height:80%;
}

.fake {
    height: 0;
}
