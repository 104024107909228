.swiperContainer {
    /* position: relative; */
    /* width: var(--slide-width); */
    width: 90%;
}

.swiper-slide {
    z-index: 0;
}

.swiper {
    height: auto;
    width: 100%;
}
