.phone-frame {
    /* Set the background image */
    background-image: url('./assets/Frame2.png');
    background-repeat: no-repeat;
    background-size: contain;
}

/* Apply styles for screens wider than 768px */
/*  @media only screen and (min-width: 768px) { */
@media only screen and (min-width: 99768px) {

    .phone-frame {
        /* Set the width and height of the phone frame */
        width: 560px;
        height: 2036px;
        margin: auto;
        padding: 50px;
        position: relative;
    }

    /* Center the app inside the phone frame */
    .phone-frame > * {
        position: absolute;
        top: 40%;
        left: -40px;
        transform: translate(10%, -50%);
    }
}

/*@media only screen and (max-width: 768px) {*/
    .ol-zoom {
        display: none;
    }
/*}*/

a {
    color: var(--toender-color) !important;
    font-weight: bolder;
}
