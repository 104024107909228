:root {
    --filter-indicator-size: 10px;
}

.indicators {
    margin-right: 8px;
}

.indicator {
    border-radius: 100px;
    width: var(--filter-indicator-size);
    height: var(--filter-indicator-size);
    float: left;
    margin-left: 3px;
}

.indicator.notChecked {
    border: 1px solid lightgrey;
}

.indicator.checked {
    background-color: rgba(211, 211, 211, 0.6);
    border: 1px solid lightgrey;
}
